@import '../../../../scss/theme-bootstrap';

.video-block {
  text-align: center;
  position: relative;
  .videojs-video__landing__inner {
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}
